@import "./fonts.scss";
@import "./tooltip.scss";

:root {
  --font-family: "Roobert Regular", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", sans-serif;
  --font-family-medium: "Roobert Medium", Roboto, "Helvetica Neue", "Noto Sans",
    "Liberation Sans", sans-serif;
  --font-family-semibold: "Roobert SemiBold", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", sans-serif;
  --scrollbar-color-thumb: #404040;
  --scrollbar-color-track: transparent;

  --neutral-800-opacity-60: rgba(38, 38, 38, 0.6);

  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-750: #2e2e2e;
  --neutral-800: #262626;
  --neutral-900: #171717;
  --neutral-950: #121212;
}

html {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
}

body {
  background: #000000;
  font-family: var(--font-family);
  margin: 0;
  min-height: 100vh;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  .wrapper {
    padding: 3rem 1rem;
    display: flex;
    height: 100%;
    flex-direction: column;
    flex: 1;
    position: relative;
    justify-content: center;
    overflow: hidden;
    @media (min-width: 992px) {
      padding: 80px;
    }
  }

  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    img {
      max-width: 100%;
      height: auto;
      margin: auto;
      width: auto;
      max-height: 100%;
    }
  }

  .header {
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      position: absolute;
      top: 80px;
      left: 80px;
      margin-bottom: 0;
    }

    .logo {
      text-align: center;

      img {
        max-width: 192px;
        height: auto;

        @media (min-width: 768px) {
          max-width: 230px;
        }
      }
    }
  }

  .footer {
    text-align: center;
    margin-top: 2rem;

    @media (min-width: 768px) {
      bottom: 80px;
      left: 80px;
      margin-top: 0;
      position: absolute;
    }

    .social-container {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    .social-title {
      color: #a3a3a3;
    }

    .socials {
      display: flex;
      column-gap: 1rem;
      align-items: center;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-start;
      }

      .social {
        width: 2rem;
        height: 2rem;
        background-color: #a3a3a3;
        border-radius: 50%;
        padding: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        transition: all 200ms ease;

        &:hover {
          background-color: #ffffff;
        }

        svg {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

@import "./home.scss";
@import "./leaderboard.scss";
