.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  color: #fff;
  white-space: normal;
  background-color: #333;
  border-radius: 4px;
  outline: 0;
  font-size: 14px;
  line-height: 1.4;
  transition-property: transform, visibility, opacity;
  position: relative;
}

.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: top;
  bottom: -7px;
  left: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: bottom;
  top: -7px;
  left: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  transform-origin: 0;
  right: -7px;
}

.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: 100%;
  left: -7px;
}

.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11);
}

.tippy-arrow {
  color: #333;
  width: 16px;
  height: 16px;
}

.tippy-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tippy-content {
  z-index: 1;
  padding: 5px 9px;
  position: relative;
}

@font-face {
  font-family: Roobert Regular;
  src: url("https://cdn.multiversx.com/fonts/roobert/regular.woff2") format("woff2"), url("https://cdn.multiversx.com/fonts/roobert/regular.woff") format("woff"), url("https://cdn.multiversx.com/fonts/roobert/regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Roobert Medium;
  src: url("https://cdn.multiversx.com/fonts/roobert/medium.woff2") format("woff2"), url("https://cdn.multiversx.com/fonts/roobert/medium.woff") format("woff"), url("https://cdn.multiversx.com/fonts/roobert/medium.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Roobert SemiBold;
  src: url("https://cdn.multiversx.com/fonts/roobert/semibold.woff2") format("woff2"), url("https://cdn.multiversx.com/fonts/roobert/semibold.woff") format("woff"), url("https://cdn.multiversx.com/fonts/roobert/semibold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Roobert Heavy;
  src: url("https://cdn.multiversx.com/fonts/roobert/heavy.woff2") format("woff2"), url("https://cdn.multiversx.com/fonts/roobert/heavy.woff") format("woff"), url("https://cdn.multiversx.com/fonts/roobert/heavy.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
}

.tooltip {
  color: #ffffff80;
  white-space: nowrap;
  background-color: #262626;
  border-radius: .5rem;
  padding: .5rem;
  font-size: .75rem;
  transition: all .2s;
  display: none;
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover {
  display: inline-block;
}

.tooltip:after {
  content: "";
  border-top: .5rem solid #262626;
  border-left: .5rem solid #0000;
  border-right: .5rem solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-wrapper {
  cursor: context-menu;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjU2IDUxMkEyNTYgMjU2IDAgMSAwIDI1NiAwYTI1NiAyNTYgMCAxIDAgMCA1MTJ6TTIxNiAzMzZoMjRWMjcySDIxNmMtMTMuMyAwLTI0LTEwLjctMjQtMjRzMTAuNy0yNCAyNC0yNGg0OGMxMy4zIDAgMjQgMTAuNyAyNCAyNHY4OGg4YzEzLjMgMCAyNCAxMC43IDI0IDI0cy0xMC43IDI0LTI0IDI0SDIxNmMtMTMuMyAwLTI0LTEwLjctMjQtMjRzMTAuNy0yNCAyNC0yNHptNDAtMjA4YTMyIDMyIDAgMSAxIDAgNjQgMzIgMzIgMCAxIDEgMC02NHoiIGZpbGw9IiM3MzczNzMiLz48L3N2Zz4=");
  width: 1rem;
  height: 1rem;
  margin-bottom: -2px;
  margin-left: .5rem;
  display: inline-block;
  position: relative;
}

.tooltip-wrapper:hover .tooltip {
  cursor: text;
  display: inline-block;
}

.tooltip-icon path {
  fill: var(--neutral-400);
}

.tooltip-container {
  color: var(--neutral-400);
  flex-direction: column;
  gap: .5rem;
  max-height: 188px;
  font-size: .75rem;
  display: flex;
  overflow-y: auto;
}

.tooltip-container .highlight {
  color: var(--neutral-100);
}

.tooltip-container > div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tooltip-container > div:first-of-type {
  margin-top: 0;
}

.tooltip-container > div:last-of-type {
  margin-bottom: 0;
}

.tooltip-container .tooltip-row {
  gap: .5rem;
  display: flex;
}

.tippy-content {
  padding: 1rem 0;
}

:root {
  --font-family: "Roobert Regular", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", sans-serif;
  --font-family-medium: "Roobert Medium", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", sans-serif;
  --font-family-semibold: "Roobert SemiBold", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", sans-serif;
  --scrollbar-color-thumb: #404040;
  --scrollbar-color-track: transparent;
  --neutral-800-opacity-60: #26262699;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-750: #2e2e2e;
  --neutral-800: #262626;
  --neutral-900: #171717;
  --neutral-950: #121212;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
  flex-direction: column;
  min-height: 100%;
  display: flex;
  position: relative;
}

body {
  font-family: var(--font-family);
  background: #000;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-size: 1rem;
  display: flex;
}

body .wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  height: 100%;
  padding: 3rem 1rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width >= 992px) {
  body .wrapper {
    padding: 80px;
  }
}

body .background {
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

body .background img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

body .header {
  margin-bottom: 2rem;
}

@media (width >= 768px) {
  body .header {
    margin-bottom: 0;
    position: absolute;
    top: 80px;
    left: 80px;
  }
}

body .header .logo {
  text-align: center;
}

body .header .logo img {
  max-width: 192px;
  height: auto;
}

@media (width >= 768px) {
  body .header .logo img {
    max-width: 230px;
  }
}

body .footer {
  text-align: center;
  margin-top: 2rem;
}

@media (width >= 768px) {
  body .footer {
    margin-top: 0;
    position: absolute;
    bottom: 80px;
    left: 80px;
  }
}

body .footer .social-container {
  flex-direction: column;
  gap: .75rem;
  display: flex;
}

body .footer .social-title {
  color: #a3a3a3;
}

body .footer .socials {
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

@media (width >= 768px) {
  body .footer .socials {
    justify-content: flex-start;
  }
}

body .footer .socials .social {
  box-sizing: border-box;
  background-color: #a3a3a3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: .25rem;
  transition: all .2s;
  display: flex;
}

body .footer .socials .social:hover {
  background-color: #fff;
}

body .footer .socials .social svg {
  max-width: 100%;
  height: auto;
}

.home .content {
  max-width: 1280px;
  margin: auto 0;
}

@media (width >= 768px) {
  .home .content {
    align-items: center;
    gap: 2rem;
    display: flex;
  }
}

@media (width >= 992px) {
  .home .content {
    gap: 80px;
  }
}

@media (width >= 1200px) {
  .home .content {
    gap: 128px;
    margin: 0 auto;
  }
}

.home .content .left {
  display: none;
}

@media (width >= 768px) {
  .home .content .left {
    width: 50%;
    display: block;
  }
}

.home .content .left .vault {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

@media (width >= 768px) {
  .home .content .right {
    width: 50%;
  }
}

.home .content .right .logo-heading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.home .content .right .logo-heading img {
  height: 60px;
  display: inline;
}

.home .content .right .logo-heading span {
  text-align: center;
  flex-basis: 0;
  padding: .5rem 0;
}

.home .content .right .logo-heading em {
  margin: .5rem 1rem .25rem 1.5rem;
}

@media (width >= 1200px) {
  .home .content .right .logo-heading {
    flex-direction: row;
  }

  .home .content .right .logo-heading span {
    flex-shrink: 0;
    flex-basis: 20rem;
  }

  .home .content .right .logo-heading span:first-child {
    text-align: right;
  }

  .home .content .right .logo-heading span:last-child {
    text-align: left;
  }
}

.home .content .right .information {
  box-sizing: border-box;
  background-color: #171717;
  border-radius: 1rem;
  max-width: 600px;
  margin: auto;
  padding: 1.5rem;
}

@media (width >= 1200px) {
  .home .content .right .information {
    padding: 2rem 1.5rem;
  }
}

.home .content .right .information .title {
  font-size: 2rem;
  line-height: 1;
  font-family: var(--font-family-medium);
  color: #fff;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

@media (width >= 1200px) {
  .home .content .right .information .title {
    font-size: 56px;
  }
}

.home .content .right .information .subtitle {
  color: #a3a3a3;
  font-family: var(--font-family-medium);
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.home .content .right .information .prize {
  color: #fff;
  background: #000;
  border-radius: .5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 1rem;
}

@media (width >= 1200px) {
  .home .content .right .information .prize {
    padding: 1rem 1.5rem;
  }
}

.home .content .right .information .prize .prize-label {
  margin-bottom: .5rem;
  font-size: 1rem;
  line-height: 1;
}

.home .content .right .information .prize .prize-sum {
  font-size: 1.5rem;
  line-height: 1;
}

@media (width >= 1200px) {
  .home .content .right .information .prize .prize-sum {
    font-size: 3rem;
  }
}

.home .content .right .information .leaderboard-link {
  color: #23f7dd;
  text-align: center;
  justify-content: center;
  width: fit-content;
  margin: 2rem auto 0;
  line-height: 1;
  transition: all .2s;
  display: flex;
  position: relative;
}

.home .content .right .information .leaderboard-link.disabled {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

.home .content .right .information .leaderboard-link:hover .tooltip {
  opacity: 1;
}

.home .content .right .button {
  cursor: pointer;
  line-height: 1;
  font-family: var(--font-family-semibold);
  color: #fff;
  background-color: #262626;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

@media (width <= 392px) {
  .home .content .right .button {
    font-size: .825rem;
  }
}

.home .content .right .button:hover {
  color: #171717;
  background-color: #23f7dd;
}

.home .content .right .button.active .button-visible {
  min-width: 1.25rem;
  display: block;
}

.home .content .right .button.active .button-invisible {
  display: none;
}

.home .content .right .button-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  display: flex;
  position: relative;
}

.home .content .right .button-wrapper.button-wrapper-long {
  width: 100%;
}

.home .content .right .button-primary {
  color: #171717;
  background-color: #23f7dd;
  font-weight: 400;
}

.home .content .right .button-primary:hover {
  opacity: .5;
}

.home .content .right .button-neutral-800 {
  font-family: var(--font-family-medium);
  color: #fff;
  background-color: #262626;
  font-weight: 500;
}

.home .content .right .button-neutral-800:hover {
  color: #fff;
  background-color: #404040;
}

.home .content .right .button-visible {
  display: none;
}

.home .content .right .button-invisible {
  min-width: 1.375rem;
}

.home .content .right .button-icons {
  justify-content: center;
  align-items: center;
  width: 1.375rem;
  display: flex;
}

.home .content .right .button-icon {
  width: 1.25rem;
  margin-left: .5rem;
}

.home .content .right .button-icon path {
  fill: #ffffff80;
  transition: all .2s;
}

.home .content .right .button-long {
  width: 100%;
  position: relative;
}

.home .content .right .button-long.active {
  color: #171717;
  background-color: #23f7dd;
}

.home .content .right .button-long.active svg path, .home .content .right .button-long:hover svg path {
  fill: #000;
}

.home .content .right .button-disabled {
  cursor: default;
  color: #ffffff80;
  position: relative;
}

.home .content .right .button-disabled:hover {
  color: #ffffff80;
  background-color: #262626;
}

.home .content .right .button-soon {
  cursor: default;
  position: relative;
}

.home .content .right .button-soon:hover .tooltip {
  display: inline-block;
}

.home .content .right .buttons {
  flex-flow: column wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  display: flex;
}

@media (width >= 576px) {
  .home .content .right .buttons {
    flex-direction: row;
  }
}

@media (width >= 768px) {
  .home .content .right .buttons {
    flex-direction: column;
  }
}

@media (width >= 992px) {
  .home .content .right .buttons {
    flex-direction: row;
  }
}

@media (width >= 1200px) {
  .home .content .right .buttons {
    gap: 1.5rem;
  }
}

.home .content .right .details {
  gap: 1rem;
  margin-top: 2rem;
  display: flex;
}

@media (width <= 767px) {
  .home .content .right .details {
    flex-wrap: wrap;
  }
}

.home .content .right .maintenance {
  text-align: center;
  background-color: #171717bf;
  border: 1px solid #fff;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1rem auto 0;
  padding: 1.5rem .75rem;
  display: flex;
}

@media (width >= 1200px) {
  .home .content .right .maintenance {
    padding: 1.5rem 1.25rem;
  }
}

.home .content .right .maintenance .maintenance-title {
  font-family: var(--font-family-medium);
  color: #a3a3a3;
  margin-bottom: 1rem;
  font-size: 28px;
  line-height: 1;
}

.home .content .right .maintenance .maintenance-subtitle {
  font-family: var(--font-family-medium);
  color: #a3a3a3;
  font-size: 1.25rem;
  line-height: 1;
}

.home .content .right .countdown {
  text-align: center;
  max-width: 600px;
  margin: 3rem auto 0;
}

.home .content .right .countdown .countdown-label {
  font-family: var(--font-family-medium);
  color: #a3a3a3;
  margin-bottom: 1.5rem;
  font-size: 28px;
  line-height: 1;
}

.home .content .right .countdown .countdown-boxes {
  background-color: #171717bf;
  border-radius: 1rem;
  align-items: center;
  padding: 1.5rem .75rem;
  display: flex;
}

@media (width >= 1200px) {
  .home .content .right .countdown .countdown-boxes {
    padding: 1.5rem 1.25rem;
  }
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper {
  justify-content: center;
  width: 25%;
  display: flex;
  position: relative;
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper:before, .home .content .right .countdown .countdown-boxes .countdown-box-wrapper:after {
  opacity: .25;
  content: "";
  background-color: #a3a3a3;
  width: .25rem;
  height: .25rem;
  position: absolute;
  top: .75rem;
  right: calc(100% - 2px);
  transform: translateY(-50%);
}

@media (width >= 1200px) {
  .home .content .right .countdown .countdown-boxes .countdown-box-wrapper:before, .home .content .right .countdown .countdown-boxes .countdown-box-wrapper:after {
    width: .5rem;
    height: .5rem;
    top: 1.25rem;
    right: calc(100% - .25rem);
  }
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper:after {
  top: 1.5rem;
}

@media (width >= 1200px) {
  .home .content .right .countdown .countdown-boxes .countdown-box-wrapper:after {
    top: 36px;
  }
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper:first-child:before, .home .content .right .countdown .countdown-boxes .countdown-box-wrapper:first-child:after {
  content: none;
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper .countdown-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  display: flex;
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper .countdown-box .countdown-value {
  font-family: var(--font-family-medium);
  color: #e5e5e5;
  opacity: .5;
  font-size: 2rem;
  line-height: 1;
}

@media (width >= 1200px) {
  .home .content .right .countdown .countdown-boxes .countdown-box-wrapper .countdown-box .countdown-value {
    margin-bottom: .5rem;
    font-size: 3rem;
  }
}

.home .content .right .countdown .countdown-boxes .countdown-box-wrapper .countdown-box .countdown-time {
  opacity: .25;
  color: #a3a3a3;
  font-size: .75rem;
  line-height: 1;
}

@media (width >= 1200px) {
  .home .content .right .countdown .countdown-boxes .countdown-box-wrapper .countdown-box .countdown-time {
    font-size: 1.5rem;
  }
}

.home .content .right .timeline {
  justify-content: space-between;
  align-items: flex-start;
  max-width: 600px;
  margin: 2rem auto 0;
  display: flex;
}

.home .content .right .timeline .timeline-item {
  width: 112px;
}

.home .content .right .timeline .timeline-item .timeline-item-date {
  color: #737373;
  background-color: #171717;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  line-height: 1;
  display: flex;
  position: relative;
}

.home .content .right .timeline .timeline-item .timeline-item-date:after {
  z-index: -1;
  background-image: linear-gradient(to right, #23f7dd 0%, #0000 100%);
  width: calc(100vw - 64px);
  max-width: 600px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (width >= 768px) {
  .home .content .right .timeline .timeline-item .timeline-item-date:after {
    width: calc(50vw - 80px - 1rem);
    max-width: none;
  }
}

@media (width >= 992px) {
  .home .content .right .timeline .timeline-item .timeline-item-date:after {
    width: calc(50vw - 120px);
  }
}

@media (width >= 1200px) {
  .home .content .right .timeline .timeline-item .timeline-item-date:after {
    width: calc(min(100vw, 1280px) / 2 - 64px);
  }
}

.home .content .right .timeline .timeline-item .timeline-item-name {
  color: #737373;
  font-size: 1rem;
  line-height: 1;
}

@media (width >= 576px) {
  .home .content .right .timeline .timeline-item .timeline-item-name {
    font-size: 1.5rem;
  }
}

@media (width >= 768px) {
  .home .content .right .timeline .timeline-item .timeline-item-name {
    white-space: nowrap;
    font-size: 1rem;
  }
}

@media (width >= 992px) {
  .home .content .right .timeline .timeline-item .timeline-item-name {
    font-size: 1.5rem;
  }
}

.home .content .right .timeline .timeline-item.timeline-item-current .timeline-item-date {
  color: #23f7dd;
  background-color: #143736;
}

.home .content .right .timeline .timeline-item.timeline-item-current .timeline-item-date:after {
  content: "";
  background-color: #81fbe9;
  background-image: none;
}

.home .content .right .timeline .timeline-item.timeline-item-current .timeline-item-name {
  color: #81fbe9;
}

.loader-row td {
  text-align: center;
}

.loader-row td .loader-wrapper {
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  display: flex;
}

.loader {
  aspect-ratio: 1;
  border: 8px solid #23f7dd;
  border-radius: 50%;
  width: 50px;
  animation: .8s linear infinite alternate l20-1, 1.6s linear infinite l20-2;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  62.5% {
    clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
  }
}

@keyframes l20-2 {
  0% {
    transform: scaleY(1)rotate(0);
  }

  49.99% {
    transform: scaleY(1)rotate(135deg);
  }

  50% {
    transform: scaleY(-1)rotate(0);
  }

  100% {
    transform: scaleY(-1)rotate(-135deg);
  }
}

.leaderboard .wrapper {
  justify-content: start;
}

.leaderboard .social-leaderboard-wrapper, .leaderboard .leaderboard-wrapper {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
  width: calc(100vw - 2rem);
  max-width: 1200px;
  margin: 1rem auto 2rem;
}

@media (width >= 767px) {
  .leaderboard .social-leaderboard-wrapper, .leaderboard .leaderboard-wrapper {
    margin-top: 6rem;
    margin-bottom: 8rem;
  }
}

@media (width >= 992px) {
  .leaderboard .social-leaderboard-wrapper, .leaderboard .leaderboard-wrapper {
    width: 100%;
  }
}

@media (width >= 1200px) {
  .leaderboard .social-leaderboard-wrapper, .leaderboard .leaderboard-wrapper {
    width: 80vw;
    margin-top: 0;
    margin-bottom: 6rem;
  }
}

.leaderboard .social-leaderboard-wrapper .title, .leaderboard .leaderboard-wrapper .title {
  font-size: 2rem;
  line-height: 1;
  font-family: var(--font-family-medium);
  color: #fff;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

@media (width >= 1200px) {
  .leaderboard .social-leaderboard-wrapper .title, .leaderboard .leaderboard-wrapper .title {
    font-size: 42px;
  }
}

@media (width >= 767px) {
  .leaderboard .social-leaderboard-wrapper .title, .leaderboard .leaderboard-wrapper .title {
    text-align: center;
  }
}

.leaderboard .social-leaderboard-wrapper .table-wrapper, .leaderboard .leaderboard-wrapper .table-wrapper {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
  color: var(--neutral-400);
  border-radius: .5rem;
  height: calc(100dvh - 20.5rem);
  min-height: 320px;
  position: relative;
  overflow: auto;
}

.leaderboard .social-leaderboard-wrapper .table-wrapper .table, .leaderboard .leaderboard-wrapper .table-wrapper .table {
  white-space: nowrap;
  height: 100%;
}

.leaderboard .social-leaderboard-wrapper .table-wrapper .table td, .leaderboard .leaderboard-wrapper .table-wrapper .table td {
  vertical-align: middle;
}

.leaderboard .social-leaderboard-wrapper .table, .leaderboard .leaderboard-wrapper .table {
  color: var(--neutral-200);
  border-color: var(--neutral-800);
  background-color: var(--neutral-950);
  vertical-align: middle;
  caption-side: bottom;
  border-collapse: collapse;
  border-radius: .5rem;
  width: 100%;
  position: relative;
}

.leaderboard .social-leaderboard-wrapper .table thead, .leaderboard .social-leaderboard-wrapper .table tbody, .leaderboard .social-leaderboard-wrapper .table tfoot, .leaderboard .social-leaderboard-wrapper .table tr, .leaderboard .social-leaderboard-wrapper .table td, .leaderboard .social-leaderboard-wrapper .table th, .leaderboard .leaderboard-wrapper .table thead, .leaderboard .leaderboard-wrapper .table tbody, .leaderboard .leaderboard-wrapper .table tfoot, .leaderboard .leaderboard-wrapper .table tr, .leaderboard .leaderboard-wrapper .table td, .leaderboard .leaderboard-wrapper .table th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.leaderboard .social-leaderboard-wrapper .table th, .leaderboard .social-leaderboard-wrapper .table td, .leaderboard .leaderboard-wrapper .table th, .leaderboard .leaderboard-wrapper .table td {
  padding: 1rem;
}

.leaderboard .social-leaderboard-wrapper .table th, .leaderboard .leaderboard-wrapper .table th {
  z-index: 1;
  text-align: left;
  background-color: var(--neutral-800);
  width: 25%;
  height: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 400;
  position: sticky;
  top: 0;
  border-bottom-width: 0 !important;
}

.leaderboard .social-leaderboard-wrapper .table th:first-of-type, .leaderboard .leaderboard-wrapper .table th:first-of-type {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  width: 5%;
  padding-left: 1rem;
}

.leaderboard .social-leaderboard-wrapper .table th:nth-of-type(2), .leaderboard .leaderboard-wrapper .table th:nth-of-type(2) {
  width: 50%;
}

.leaderboard .social-leaderboard-wrapper .table th:last-of-type, .leaderboard .leaderboard-wrapper .table th:last-of-type {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.leaderboard .social-leaderboard-wrapper .table td, .leaderboard .leaderboard-wrapper .table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.leaderboard .social-leaderboard-wrapper .table td:first-of-type, .leaderboard .leaderboard-wrapper .table td:first-of-type {
  padding-left: 1rem;
}

.leaderboard .social-leaderboard-wrapper .table tr:first-of-type td:first-of-type, .leaderboard .leaderboard-wrapper .table tr:first-of-type td:first-of-type {
  border-top-left-radius: .5rem;
}

.leaderboard .social-leaderboard-wrapper .table tr:first-of-type td:last-of-type, .leaderboard .leaderboard-wrapper .table tr:first-of-type td:last-of-type {
  border-top-right-radius: .5rem;
}

.leaderboard .social-leaderboard-wrapper .table tr:last-of-type td, .leaderboard .leaderboard-wrapper .table tr:last-of-type td {
  border-bottom: 0;
}

.leaderboard .social-leaderboard-wrapper .table > thead, .leaderboard .leaderboard-wrapper .table > thead {
  vertical-align: bottom;
  text-align: left;
}

.leaderboard .social-leaderboard-wrapper .table > :not(caption) > * > *, .leaderboard .leaderboard-wrapper .table > :not(caption) > * > * {
  border-bottom-width: 1px;
}

.social-leaderboard-wrapper .twitter-tweet.twitter-tweet-rendered + .tweets-skeleton {
  display: none;
}

.social-leaderboard-wrapper .twitter-tweet {
  width: 360px !important;
  margin-top: 0 !important;
}

.social-leaderboard-wrapper blockquote.twitter-tweet, .social-leaderboard-wrapper blockquote.twitter-tweet p {
  display: none;
}

.social-leaderboard-wrapper .tweeter-tweet-rendered blockquote {
  display: none !important;
}

.social-leaderboard-wrapper .table th:nth-of-type(2), .social-leaderboard-wrapper .table td:nth-of-type(2) {
  width: 3rem !important;
}

.social-leaderboard-wrapper .table td {
  vertical-align: top !important;
}

.tweets, .tweets-skeleton {
  flex-flow: wrap;
  justify-content: start;
  width: 100%;
  margin-top: -.625rem;
  padding: 0;
  display: flex;
}

.tweet, .tweet-skeleton {
  margin: 0;
}

.tweet-skeleton, .tweets-skeleton {
  width: 360px;
}

.tweet-skeleton {
  background-color: #15202b;
  border: 1px solid #425364;
  border-radius: 1rem;
  height: 360px;
  padding: 1.5rem;
}

.tweet-skeleton .img {
  background-color: #262626;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

.tweet-skeleton .content-1, .tweet-skeleton .content-2 {
  height: 25%;
}

.tweet-skeleton .line {
  background-color: #262626;
  border-radius: .3rem;
  width: 100%;
  height: 15%;
  margin: .5rem 0;
}

.tweet-skeleton .line:last-child {
  width: 75%;
}

.tweet-skeleton .img {
  background-color: #262626;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  animation: 1s linear infinite alternate tweet-skeleton;
}

.tweet-skeleton .line {
  background-color: #262626;
  border-radius: .3rem;
  width: 100%;
  height: 15%;
  margin: .5rem 0;
  animation: 1s linear infinite alternate tweet-skeleton;
}

@keyframes tweet-skeleton {
  0% {
    background-color: #262626;
  }

  100% {
    background-color: #121212;
  }
}
/*# sourceMappingURL=index.889a902f.css.map */
