@import "/node_modules/tippy.js/dist/tippy.css";

.tooltip {
  position: absolute;
  transition: all 200ms ease;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #262626;
  color: rgba(#ffffff, 0.5);
  font-size: 0.75rem;
  display: none;
  white-space: nowrap;

  &:hover {
    display: inline-block;
  }

  &:after {
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid #262626;
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.tooltip-wrapper {
  cursor: context-menu;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNMjU2IDUxMkEyNTYgMjU2IDAgMSAwIDI1NiAwYTI1NiAyNTYgMCAxIDAgMCA1MTJ6TTIxNiAzMzZoMjRWMjcySDIxNmMtMTMuMyAwLTI0LTEwLjctMjQtMjRzMTAuNy0yNCAyNC0yNGg0OGMxMy4zIDAgMjQgMTAuNyAyNCAyNHY4OGg4YzEzLjMgMCAyNCAxMC43IDI0IDI0cy0xMC43IDI0LTI0IDI0SDIxNmMtMTMuMyAwLTI0LTEwLjctMjQtMjRzMTAuNy0yNCAyNC0yNHptNDAtMjA4YTMyIDMyIDAgMSAxIDAgNjQgMzIgMzIgMCAxIDEgMC02NHoiIGZpbGw9IiM3MzczNzMiLz48L3N2Zz4=");
  width: 1rem;
  height: 1rem;
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: -2px;
  &:hover {
    .tooltip {
      display: inline-block;
      cursor: text;
    }
  }
}

.tooltip-icon {
  path {
    fill: var(--neutral-400);
  }
}

.tooltip-container {
  max-height: 188px;
  overflow-y: auto;
  font-size: 0.75rem;
  color: var(--neutral-400);
  .highlight {
    color: var(--neutral-100);
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & > div {
    padding-right: 1rem;
    padding-left: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .tooltip-row {
    display: flex;
    gap: 0.5rem;
  }
}

.tippy-content {
  padding: 1rem 0;
}
