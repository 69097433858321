.home {
  .content {
    margin: auto 0;
    max-width: 1280px;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    @media (min-width: 992px) {
      gap: 80px;
    }

    @media (min-width: 1200px) {
      margin: 0 auto;
      gap: 128px;
    }

    .left {
      display: none;

      @media (min-width: 768px) {
        width: 50%;
        display: block;
      }

      .vault {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
      }
    }

    .right {
      @media (min-width: 768px) {
        width: 50%;
      }

      .logo-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        margin-top: 2rem;
        img {
          display: inline;
          height: 60px;
        }
        span {
          flex-basis: 0;
          text-align: center;
          padding: 0.5rem 0 0.5rem 0;
        }
        em {
          margin: 0.5rem 1rem 0.25rem 1.5rem;
        }
        @media (min-width: 1200px) {
          flex-direction: row;

          span {
            flex-shrink: 0;
            flex-basis: 20rem;

            &:first-child {
              text-align: right;
            }

            &:last-child {
              text-align: left;
            }
          }
        }
      }

      .information {
        background-color: #171717;
        max-width: 600px;
        border-radius: 1rem;
        padding: 1.5rem;
        margin: auto;
        box-sizing: border-box;

        @media (min-width: 1200px) {
          padding: 2rem 1.5rem;
        }

        .title {
          line-height: 1;
          font-size: 2rem;
          margin-top: 0;
          margin-bottom: 1.5rem;
          font-family: var(--font-family-medium);
          color: #ffffff;

          @media (min-width: 1200px) {
            font-size: 56px;
          }
        }

        .subtitle {
          color: #a3a3a3;
          margin-bottom: 1rem;
          font-family: var(--font-family-medium);
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 1.5rem;
        }

        .prize {
          margin-bottom: 1rem;
          border-radius: 0.5rem;
          padding: 1rem;
          font-size: 1rem;
          color: #ffffff;
          background: #000000;

          @media (min-width: 1200px) {
            padding: 1rem 1.5rem;
          }

          .prize-label {
            font-size: 1rem;
            margin-bottom: 0.5rem;
            line-height: 1;
          }

          .prize-sum {
            line-height: 1;
            font-size: 1.5rem;

            @media (min-width: 1200px) {
              font-size: 3rem;
            }
          }
        }

        .leaderboard-link {
          display: flex;
          margin: 2rem auto 0;
          color: #23f7dd;
          line-height: 1;
          transition: all 200ms ease;
          position: relative;
          text-align: center;
          justify-content: center;
          width: fit-content;
          &.disabled {
            cursor: default;
            text-decoration: none;
            pointer-events: none;
          }
          &:hover {
            .tooltip {
              opacity: 1;
            }
          }
        }
      }

      .button {
        cursor: pointer;
        transition: all 200ms ease;
        line-height: 1;
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: center;
        font-family: var(--font-family-semibold);
        font-weight: 600;
        height: 3rem;
        background-color: #262626;
        color: #ffffff;
        border-radius: 0.5rem;
        width: 100%;
        @media (max-width: 392px) {
          font-size: 0.825rem;
        }
        &:hover {
          color: #171717;
          background-color: #23f7dd;
        }

        &.active {
          .button-visible {
            min-width: 1.25rem;
            display: block;
          }

          .button-invisible {
            display: none;
          }
        }
      }

      .button-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        &.button-wrapper-long {
          width: 100%;
        }
      }

      .button-primary {
        color: #171717;
        background-color: #23f7dd;
        font-weight: 400;
        &:hover {
          opacity: 0.5;
        }
      }

      .button-neutral-800 {
        font-family: var(--font-family-medium);
        font-weight: 500;
        color: #ffffff;
        background-color: #262626;

        &:hover {
          color: #ffffff;
          background-color: #404040;
        }
      }

      .button-visible {
        display: none;
      }

      .button-invisible {
        min-width: 1.375rem;
      }

      .button-icons {
        width: 1.375rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-icon {
        width: 1.25rem;
        margin-left: 0.5rem;

        path {
          fill: rgba(#ffffff, 0.5);
          transition: all 200ms ease;
        }
      }

      .button-long {
        width: 100%;
        position: relative;

        &.active {
          color: #171717;
          background-color: #23f7dd;

          svg path {
            fill: black;
          }
        }

        &:hover svg path {
          fill: black;
        }
      }

      .button-disabled {
        cursor: default;
        color: rgba(#ffffff, 0.5);
        position: relative;

        &:hover {
          background-color: #262626;
          color: rgba(#ffffff, 0.5);
        }
      }

      .button-soon {
        position: relative;
        cursor: default;
        &:hover {
          .tooltip {
            display: inline-block;
          }
        }
      }

      .buttons {
        display: flex;
        margin-top: 1.5rem;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;

        @media (min-width: 576px) {
          flex-direction: row;
        }

        @media (min-width: 768px) {
          flex-direction: column;
        }

        @media (min-width: 992px) {
          flex-direction: row;
        }

        @media (min-width: 1200px) {
          gap: 1.5rem;
        }
      }

      .details {
        display: flex;
        gap: 1rem;
        margin-top: 2rem;
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
      }

      .maintenance {
        width: 100%;
        text-align: center;
        margin: 1rem auto 0;
        padding: 1.5rem 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(#171717, 0.75);
        border: 1px solid #ffffff;
        border-radius: 1rem;

        @media (min-width: 1200px) {
          padding: 1.5rem 1.25rem;
        }

        .maintenance-title {
          font-family: var(--font-family-medium);
          font-size: 28px;
          margin-bottom: 1rem;
          line-height: 1;
          color: #a3a3a3;
        }

        .maintenance-subtitle {
          font-family: var(--font-family-medium);
          font-size: 1.25rem;
          line-height: 1;
          color: #a3a3a3;
        }
      }

      .countdown {
        max-width: 600px;
        text-align: center;
        margin: 3rem auto 0;

        .countdown-label {
          font-family: var(--font-family-medium);
          font-size: 28px;
          margin-bottom: 1.5rem;
          line-height: 1;
          color: #a3a3a3;
        }

        .countdown-boxes {
          padding: 1.5rem 0.75rem;
          display: flex;
          align-items: center;
          background-color: rgba(#171717, 0.75);
          border-radius: 1rem;

          @media (min-width: 1200px) {
            padding: 1.5rem 1.25rem;
          }

          .countdown-box-wrapper {
            width: 25%;
            display: flex;
            position: relative;
            justify-content: center;

            &:before,
            &:after {
              width: 0.25rem;
              height: 0.25rem;
              opacity: 0.25;
              background-color: #a3a3a3;
              position: absolute;
              content: "";
              right: calc(100% - 2px);
              transform: translateY(-50%);
              top: 0.75rem;

              @media (min-width: 1200px) {
                width: 0.5rem;
                right: calc(100% - 0.25rem);
                height: 0.5rem;
                top: 1.25rem;
              }
            }

            &:after {
              top: 1.5rem;

              @media (min-width: 1200px) {
                top: 36px;
              }
            }

            &:first-child {
              &:before,
              &:after {
                content: none;
              }
            }

            .countdown-box {
              width: 96px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .countdown-value {
                font-family: var(--font-family-medium);
                font-size: 2rem;
                color: #e5e5e5;
                line-height: 1;
                opacity: 0.5;

                @media (min-width: 1200px) {
                  margin-bottom: 0.5rem;
                  font-size: 3rem;
                }
              }

              .countdown-time {
                font-size: 0.75rem;
                line-height: 1;
                opacity: 0.25;
                color: #a3a3a3;

                @media (min-width: 1200px) {
                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }

      .timeline {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 600px;
        margin: 2rem auto 0;

        .timeline-item {
          width: 112px;

          .timeline-item-date {
            line-height: 1;
            font-size: 1rem;
            color: #737373;
            padding: 1rem 1.25rem;
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #171717;
            margin-bottom: 1rem;
            position: relative;

            &:after {
              position: absolute;
              top: 50%;
              width: calc(100vw - 64px);
              max-width: 600px;
              height: 1px;
              z-index: -1;
              left: 0;
              transform: translateY(-50%);
              background-image: linear-gradient(
                to right,
                #23f7dd 0%,
                transparent 100%
              );

              @media (min-width: 768px) {
                width: calc((100vw - 160px - 2rem) / 2);
                max-width: none;
              }

              @media (min-width: 992px) {
                width: calc((100vw - 160px - 80px) / 2);
              }

              @media (min-width: 1200px) {
                width: calc((min(100vw, 1280px) - 128px) / 2);
              }
            }
          }

          .timeline-item-name {
            font-size: 1rem;
            line-height: 1;
            color: #737373;

            @media (min-width: 576px) {
              font-size: 1.5rem;
            }

            @media (min-width: 768px) {
              white-space: nowrap;
              font-size: 1rem;
            }

            @media (min-width: 992px) {
              font-size: 1.5rem;
            }
          }

          &.timeline-item-current {
            .timeline-item-date {
              background-color: #143736;
              color: #23f7dd;

              &:after {
                content: "";
                background-image: none;
                background-color: #81fbe9;
              }
            }

            .timeline-item-name {
              color: #81fbe9;
            }
          }
        }
      }
    }
  }
}
