@import "./loader.scss";

.leaderboard {
  .wrapper {
    justify-content: start;
  }

  .social-leaderboard-wrapper,
  .leaderboard-wrapper {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);

    margin: 1rem auto 2rem;
    width: calc(100vw - 2rem);
    max-width: 1200px;

    @media (min-width: 767px) {
      margin-top: 6rem;
      margin-bottom: 8rem;
    }
    @media (min-width: 992px) {
      width: 100%;
    }

    @media (min-width: 1200px) {
      width: 80vw;
      margin-top: 0;
      margin-bottom: 6rem;
    }

    .title {
      line-height: 1;
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-family: var(--font-family-medium);
      color: #ffffff;

      @media (min-width: 1200px) {
        font-size: 42px;
      }
      @media (min-width: 767px) {
        text-align: center;
      }
    }

    .table-wrapper {
      scroll-behavior: smooth;
      scrollbar-width: thin;
      scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);

      overflow-x: auto;
      overflow-y: auto;
      min-height: 320px;
      height: calc(100dvh - 20.5rem);
      color: var(--neutral-400);
      position: relative;
      border-radius: 0.5rem;
      .table {
        height: 100%;
        white-space: nowrap;
        td {
          vertical-align: middle;
        }
      }
    }
    .table {
      border-radius: 0.5rem;
      width: 100%;
      color: var(--neutral-200);
      border-color: var(--neutral-800);
      background-color: var(--neutral-950);
      vertical-align: middle;
      caption-side: bottom;
      border-collapse: collapse;
      position: relative;

      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
      }
      th,
      td {
        padding: 1rem;
      }
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        text-align: left;
        height: 1rem;
        font-weight: 400;
        background-color: var(--neutral-800);
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-bottom-width: 0 !important;
        width: 25%;

        &:first-of-type {
          padding-left: 1rem;
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
          width: 5%;
        }
        &:nth-of-type(2) {
          width: 50%;
        }
        &:last-of-type {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        &:first-of-type {
          padding-left: 1rem;
        }
      }
      tr:first-of-type {
        td:first-of-type {
          border-top-left-radius: 0.5rem;
        }
        td:last-of-type {
          border-top-right-radius: 0.5rem;
        }
      }
      tr:last-of-type {
        td {
          border-bottom: 0;
        }
      }
      & > thead {
        vertical-align: bottom;
        text-align: left;
      }
      & > :not(caption) > * > * {
        border-bottom-width: 1px;
      }
    }
  }
}

.social-leaderboard-wrapper {
  .twitter-tweet.twitter-tweet-rendered + .tweets-skeleton {
    display: none;
  }
  .twitter-tweet {
    width: 360px !important;
    margin-top: 0 !important;
  }
  blockquote.twitter-tweet {
    display: none;
    p {
      display: none;
    }
  }
  .tweeter-tweet-rendered {
    blockquote {
      display: none !important;
    }
  }
  .table {
    th,
    td {
      &:nth-of-type(2) {
        width: 3rem !important;
      }
    }
    td {
      vertical-align: top !important;
    }
  }
}

.tweets,
.tweets-skeleton {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: start;
  padding: 0;
  margin-top: -0.625rem;
}
.tweet,
.tweet-skeleton {
  margin: 0;
}
.tweet-skeleton,
.tweets-skeleton {
  width: 360px;
}
.tweet-skeleton {
  background-color: #15202b;
  border: 1px solid rgb(66, 83, 100);
  border-radius: 1rem;
  height: 360px;

  padding: 1.5rem;
}
.tweet-skeleton .img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background-color: rgb(38, 38, 38);
}
.tweet-skeleton .content-1,
.tweet-skeleton .content-2 {
  height: 25%;
}
.tweet-skeleton .line {
  height: 15%;
  margin: 0.5rem 0;
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(38, 38, 38);
}
.tweet-skeleton .line:last-child {
  width: 75%;
}

.tweet-skeleton .img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background-color: rgb(38, 38, 38);
  animation: tweet-skeleton 1s linear infinite alternate;
}

.tweet-skeleton .line {
  height: 15%;
  margin: 0.5rem 0;
  width: 100%;
  border-radius: 0.3rem;
  background-color: rgb(38, 38, 38);
  animation: tweet-skeleton 1s linear infinite alternate;
}

@keyframes tweet-skeleton {
  0% {
    background-color: rgb(38, 38, 38);
  }
  100% {
    background-color: rgb(18, 18, 18);
  }
}
